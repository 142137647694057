import React from "react";
const styles = require("./styles.module.css");

const IconCard = ({ icon, title, caption, backContent }) => {
  return (
    <div className={styles.card_wrapper}>
      <div className={`${styles.back} rounded shadow-lg`}>
      {backContent}
      </div>
      <div className={`${styles.front} rounded shadow-lg`}>
      <div className="flex flex-col justify-center items-center p-10">
          <img src={icon} alt="icon" height="80" width="80" className="mt-10"/>
          <p className="uppercase mt-20 font-bold">{title}</p>
          <div className="w-2/3 mt-5">
            <p className="text-gray-700 text-base text-center">{caption}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IconCard;
