import React from "react";
import { graphql, Link } from "gatsby";
import { Badge } from "components";
import Fade from "react-reveal/Fade";
import IconCard from "components/icon-card";
import DefaultLayout from "components/layout/default";
import layoutStyle from "styles/layout.module.css";
import radiusTopCapImage from "images/product-radius-top-cap.svg";
import radiusBottomCapImage from "images/product-radius-bottom-cap.svg";
import iconImage1 from "images/solutions/aws-icon-1.png";
import iconImage2 from "images/solutions/aws-icon-2.png";
import computerScreen from "images/computer-screen-1.png";

import Image1 from "images/solutions/security-on-aws-image-1.svg";
import Img from "components/image";
import { IMAGE_BACKGROUND_PROPS } from "constants/index";
import style from "styles/pages/products.module.css";
import styles from "styles/pages/solutions.module.css";
import secStyles from "styles/pages/security-aws.module.css";
import { useWindowSize } from "hooks";

function CisoPage(props) {
  const [mainImageLoaded, setMainImageLoaded] = React.useState(false);
  const [width] = useWindowSize();
  React.useEffect(() => {
    setMainImageLoaded(true);
  }, []);
  function renderVerticalLine() {
    let height = "0%";
    if (mainImageLoaded) height = "50vh";
    return (
      <div
        className={`${style.verticalLine} absolute top-0 w-px bg-white hidden md:block`}
        style={{ height }}/>
    );
  }

  function renderPageTitle() {
    return (
      <section id="page-title" className="relative h-128">
        <h3 className="font-bold text-4xl text-center lg:text-6xl mt-48">
          Security On AWS
        </h3>

        <div className="relative text-center">
          <p>
            Get visibility into the unique cloud security and compliance risks
            on Amazon Web Services.
          </p>
        </div>
        <div className="relative flex content-center justify-center mb-48 mt-10">
          {renderVerticalLine()}
        </div>

        <Img
          {...IMAGE_BACKGROUND_PROPS}
          objectFit="cover"
          alt="Background"
          fluid={props.data.backgroundImage.childImageSharp.fluid}/>
      </section>
    );
  }

  function renderSection1() {
    const subHeaderText = (
      <h3 className="font-serif italic text-2xl sm:text-2xl lg:text-6xl text-center leading-tight mt-4 whitespace-pre-wrap">
        More User Autonomy Means More Responsibility For Cloud Security
      </h3>
    );
    return (
      <section className="relative ">
        <img src={Image1} alt="security-aws-1" className="mt-10 md:-mt-20"/>
        <div className="container lg:w-2/3 mx-auto px-8 mt-10 lg:mt-20 lg:py-0 relative text-black">
          {subHeaderText}
        </div>
        <div className="container mx-auto px-8 text-black lg:w-2/3">
          <p className="text-center mt-10">
            Cloud security is not the responsibility of AWS alone, but is shared
            between AWS and you, the customer.
          </p>
          <p className="text-center mt-10 lg:pl-40 lg:pr-40">
            While AWS is responsible for protecting the physical infrastructure
            “of” the cloud, the customer is responsible for correct service
            configurations.
          </p>
        </div>
      </section>
    );
  }

  function renderCards() {
    return (
      <section className="relative bg-white text-black flex justify-center text-black bg-white pb-40 mt-40 md:mt-0">
        <div className="flex flex-wrap container pt-0 sm:pt-40">
         

          <div
            key={`item-${1}`}
            className="w-full md:w-1/2 flex justify-center content-center">
            <Fade fraction={0.5}>
              {" "}
              <IconCard
                icon={iconImage1}
                title="customers"
                caption="Responsibility for security ‘IN’ the cloud"
                backContent={
                  <div className="p-10">
                    <div>&#8226; Customer data</div>
                    <div className="mt-5">
                      &#8226; Platform, applications, identity and Access
                      Management
                    </div>
                    <div className="mt-5">
                      &#8226; Operating system, Network and Firewall
                      Configuration
                    </div>
                    <div className="mt-5">
                      &#8226; Client-side Data Encyrption, and Data Integrity
                      Authentication
                    </div>
                    <div className="mt-5">
                      &#8226; Server-side Encyrption (File System and/or Data)
                    </div>
                    <div className="mt-5">
                      &#8226; Network Traffic Protection (Encyrption/ Integrity/
                      Identity)
                    </div>
                  </div>
                }/>
            </Fade>
          </div>
          <div
            key={`item-${2}`}
            className="w-full md:w-1/2 flex justify-center content-center">
            <Fade fraction={0.5}>
              <IconCard
                icon={iconImage2}
                title="aws"
                caption="Responsibility for security ‘OF’ the cloud"
                backContent={
                  <div className="p-10">
                    <h5 className="font-bold">SOFTWARE</h5>
                    <div className="mt-5">&#8226; Compute</div>
                    <div className="mt-5">&#8226; Database</div>
                    <div className="mt-5">&#8226; Networking</div>
                    <h5 className="mt-5 font-bold">AWS GLOBAL INFRASTRUCTURE</h5>
                    <div className="mt-5">&#8226; Availibility Zones</div>
                    <div className="mt-5">&#8226; Regions</div>
                    <div className="mt-5">&#8226; Edge Locations</div>
                  </div>
                }/>
            </Fade>
          </div>
         
        </div>
      </section>
    );
  }

  function rendeSection2() {
    return (
      <section
        className={`relative text-black flex flex-col justify-center items-center mt-40 md:mt-128 mb-64 md:mb-160`}>
        <div style={{ position: "relative", zIndex: 1 }}>
          <div className="flex justify-center content-center">
            <h3 className="font-serif italic text-xl sm:text-4xl w-2/3 text-center lg:text-6xl leading-tight mt-4 text-white">
              Cloud Misconfiguration Is A Major Cause of Cloud Security
              Incidents.
            </h3>
          </div>

          <div className="flex mb-4">
            <div className="w-1/4"></div>
            <div className="w-full md:w-3/4">
              <p className="text-center mt-10 text-white text-sm md:text-base">
                Because threat actors are now targeting exploitable
                configuration states of cloud services, organizations without
                the dedicated cloud security expertise need focused solutions to
                get a step ahead of these attackers.
              </p>
            </div>
            <div className="w-1/4"></div>
          </div>
        </div>
        <img
          src={require("../../images/solutions/aws-bg-elipse-2.png")}
          style={{
            position: "absolute",
            height: width < 600 ? 450 : 1200,
            zIndex: 0
          }}
          alt="elipse"/>
      </section>
    );
  }
  function renderContactUs() {
    return (
      <section className={`relative text-black mt-48 pb-40 md:pb-0`}>
        <div className={`container mx-auto z-10`}>
          <p className="max-w-xl md:max-w-full mx-auto text-center whitespace-pre-wrap p-5 md:p-0">
            See how Warden can help address the top security risks on your AWS
            cloud environment.
          </p>
          <div className="text-center">
            <a href={"/products/warden"} className="btn btn-orange mt-6 inline-block">
              About Warden
            </a>
          </div>
        </div>
      </section>
    );
  }

  function renderSection3() {
    const subHeaderText = (
      <h3
        className="font-serif italic text-2xl sm:text-4xl lg:text-5xl leading-tight"
        style={{ maxWidth: 600 }}>
        Achieving Security Best Practices On AWS
      </h3>
    );
    return (
      <section className="relative md:pt-20">
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 p-10 md:pl-48 flex flex-col justify-content content-center">
            <div className="lg:py-0 relative text-black">{subHeaderText}</div>
            <div className="container text-black w-4/5">
              <p className="mt-10">
                AWS offers native security tools like IAM and Inspector to
                address different risk areas in your cloud environment, though
                these tools are more suited for expert cloud users.
              </p>
              <p className="mt-10">
                Warden is designed to be beginner-friendly. Complementary to
                these native tools, Warden builds rules to help any organization
                scan its cloud security posture against best practices.
              </p>
              <Link
                to={"/products/warden"}
                className="btn btn-orange mt-6 inline-block">
                About Warden
              </Link>
            </div>
          </div>
          {/* Desktop view */}
          <div className="hidden md:flex text-black flex justify-end w-full md:w-1/2 md:pr-48">
            <div className="w-auto">
              <Fade>
                <div className="max-w-sm rounded overflow-hidden shadow-lg p-5 mt-40">
                  <h3 className="font-bold">DETECTIVE CONTROLS</h3>
                  <p className="mt-2">
                    Identify potential security threats or incidents
                  </p>
                  <div className="flex mt-2">
                    <Badge>GUARDDUTY</Badge>
                    <Badge>CONFIG</Badge>
                  </div>
                </div>
              </Fade>
              <Fade>
                <div className="max-w-sm rounded overflow-hidden shadow-lg p-5 mt-64">
                  <h3 className="font-bold">DETECTIVE CONTROLS</h3>
                  <p className="mt-2">
                    Data classification and encryption best practices that
                    support business objectives
                  </p>
                  <div className="flex mt-2">
                    <Badge>MACIE</Badge>
                  </div>
                </div>
              </Fade>
            </div>
            <div className="pl-5 pr-5">
              <div style={{ borderLeft: "2px solid black" }}>
                <div className={secStyles.smallBlkCircle}/>
                <div className={`${secStyles.smallBlkCircle} mt-40`}/>
                <div className={`${secStyles.smallBlkCircle} mt-48`}/>
                <div className={`${secStyles.smallBlkCircle} mt-48`}/>
                <div className={`${secStyles.smallBlkCircle} mt-40`}/>
                <div className={`${secStyles.bigBlkCircle} mt-160`}/>
              </div>
            </div>
            <div className="w-2/5">
              <Fade>
                <div className="max-w-sm rounded overflow-hidden shadow-lg p-5">
                  <h3 className="font-bold">IDENTITY AND ACCESS MANAGEMENT</h3>
                  <p className="mt-2">
                    Only authorized and authenticated users are able to access
                    your resources
                  </p>
                  <div className="flex mt-2">
                    <Badge>IAM</Badge>
                    <Badge>ORGANIZATIONS</Badge>
                  </div>
                </div>
              </Fade>
              <Fade>
                <div className="max-w-sm rounded overflow-hidden shadow-lg p-5 mt-40">
                  <h3 className="font-bold">INFRASTRUCTURE PROTECTION</h3>
                  <p className="mt-2">
                    Control methodologies critical for successful, ongoing
                    operations in the cloud
                  </p>
                  <div className="flex mt-2">
                    <Badge>INSPECTOR</Badge>
                  </div>
                </div>
              </Fade>
              <Fade>
                <div className="max-w-sm rounded overflow-hidden shadow-lg p-5 mt-40">
                  <h3 className="font-bold">INCIDENT RESPONSE</h3>
                  <p className="mt-2">
                    Processes to respond to and mitigate the potential impact of
                    security incidents
                  </p>
                  <div className="flex mt-2">
                    <Badge>CLOUDTRAIL</Badge>
                  </div>
                </div>
              </Fade>
              <Fade>
                <div className="p-5 mt-64">
                  <h3 className="font-bold">
                    LEVERAGE WARDEN’S CONTINUOUS RISK MONITORING
                  </h3>
                  <p className="mt-2">
                    As organizations innovate in the cloud, having continuous
                    security assurance helps them track all their configuration
                    changes to more quickly comply to AWS security best
                    practices.
                  </p>
                </div>
              </Fade>
            </div>
          </div>
          {/* Mobile View */}
          <div className=" md:hidden text-black flex w-full md:w-1/2 lg:w-2/3 mt-20">
            <div className="flex">
              <div className="w-auto pl-3 pr-1">
                <div style={{ borderLeft: "2px solid black" }}>
                  <div className={`${secStyles.smallBlkCircle}`}/>
                  <div className={`${secStyles.smallBlkCircle} mt-48`}/>
                  <div className={`${secStyles.smallBlkCircle} mt-64`}/>
                  <div className={`${secStyles.smallBlkCircle} mt-48`}/>
                  <div className={`${secStyles.smallBlkCircle} mt-48`}/>
                  <div className={`${secStyles.bigBlkCircle} mt-96`}/>
                </div>
              </div>
              <div className="w-auto">
                <Fade>
                  <div className="max-w-sm rounded overflow-hidden shadow-lg p-5">
                    <h3 className="font-bold">DETECTIVE CONTROLS</h3>
                    <p className="mt-2">
                      Identify potential security threats or incidents
                    </p>
                    <div className="flex mt-2">
                      <Badge>GUARDDUTY</Badge>
                      <Badge>CONFIG</Badge>
                    </div>
                  </div>
                </Fade>
                <Fade>
                  <div className="max-w-sm rounded overflow-hidden shadow-lg p-5 mt-10">
                    <h3 className="font-bold">DETECTIVE CONTROLS</h3>
                    <p className="mt-2">
                      Data classification and encryption best practices that
                      support business objectives
                    </p>
                    <div className="flex mt-2">
                      <Badge>MACIE</Badge>
                    </div>
                  </div>
                </Fade>
                <Fade>
                  <div className="max-w-sm rounded overflow-hidden shadow-lg p-5 mt-10">
                    <h3 className="font-bold">
                      IDENTITY AND ACCESS MANAGEMENT
                    </h3>
                    <p className="mt-2">
                      Only authorized and authenticated users are able to access
                      your resources
                    </p>
                    <div className="flex mt-2">
                      <Badge>IAM</Badge>
                      <Badge>ORGANISATIONS</Badge>
                    </div>
                  </div>
                </Fade>
                <Fade>
                  <div className="max-w-sm rounded overflow-hidden shadow-lg p-5 mt-10">
                    <h3 className="font-bold">INFRASTRUCTURE PROTECTION</h3>
                    <p className="mt-2">
                      Control methodologies critical for successful, ongoing
                      operations in the cloud
                    </p>
                    <div className="flex mt-2">
                      <Badge>INSPECTOR</Badge>
                    </div>
                  </div>
                </Fade>
                <Fade>
                  <div className="max-w-sm rounded overflow-hidden shadow-lg p-5 mt-10">
                    <h3 className="font-bold">INCIDENT RESPONSE</h3>
                    <p className="mt-2">
                      Processes to respond to and mitigate the potential impact
                      of security incidents
                    </p>
                    <div className="flex mt-2">
                      <Badge>CLOUDTRAIL</Badge>
                    </div>
                  </div>
                </Fade>
                <Fade>
                  <div className="pt-5 pb-5 pr-5 mt-10">
                    <h3 className="font-bold">
                      LEVERAGE WARDEN’S CONTINUOUS RISK MONITORING
                    </h3>
                    <p className="mt-2">
                      As organizations innovate in the cloud, having continuous
                      security assurance helps them track all their
                      configuration changes to more quickly comply to AWS
                      security best practices.
                    </p>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
          <img
            src={computerScreen}
            alt="screen"
            className={`${styles.awsComputerScreen} mt-20 md:mt-0`}/>
        </div>
      </section>
    );
  }

  return (
    <DefaultLayout
      location={props.location}
      title="Security On AWS"
      description="Get visibility into the unique cloud security and compliance risks on Amazon Web Services.">
      <div className={layoutStyle.headerPlaceholder}/>
      {renderPageTitle()}
      <img
        src={radiusTopCapImage}
        alt="Background"
        className="w-full z-1 pointer-events-none"/>
      <div className="relative z-10 -mt-32 sm:-mt-32 overflow-hidden">
        <div
          className="bg-white absolute w-full"
          style={{ top: "127px", bottom: "127px" }}/>
        {renderSection1()}
        {renderCards()}
        {rendeSection2()}
        {renderSection3()}
        {renderContactUs()}
      </div>
      <img
        src={radiusBottomCapImage}
        alt="Background"
        className="w-full z-1 -mt-32 pointer-events-none"/>
      <div className="pb-48"/>
    </DefaultLayout>
  );
}

export default React.memo(CisoPage);

export const QUERY = graphql`
  query SolutionsSecurityQuery {
    pageContent: file(
      relativePath: { eq: "solution-pages/penetrationtesting.json" }
    ) {
      childProductPagesJson {
        title
        description
      }
    }
    backgroundImage: file(
      relativePath: { eq: "background-products-item.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: PNG, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    screenImage: file(relativePath: { eq: "computer-screen-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: PNG, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
